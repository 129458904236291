import * as React from "react"
import CommunityPage from "../components/communitymain"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import languages from "../utils/enums/languages"

const Community = ({ location, pageContext: { translate } }) => (
  <Layout
    secondary
    pageContext={{ translate }}
    path="eraofwe"
    location={location}
    SEO={({ data }) => (
      <SEO
        title={data.t?.our_users_share}
        description={data.t?.our_community_consists}
        keywords={data.t?.keywords_community}
        languages={[
          ...languages.map(l => {
            return {
              hrefLang: l || 'en',
              href: `${process.env.GATSBY_SITE_URL}${
                l ? "/" + l + "/" : "/"
              }community/`,
            }
          }),
          {
            hrefLang: "x-default",
            href: `${process.env.GATSBY_SITE_URL}/community/`,
          },
        ]}
      />
    )}
  >
    <>
      {
        <>
          <CommunityPage pageContext={{ translate }} />
        </>
      }
    </>
  </Layout>
)

export default Community
