import * as React from "react"
import Whitepaper from "../models/whitepaper"

const Positive = ({
  img,
  description,
  btntext,
  btnlink,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="positive">
          <div className="content">
            <div className="detail">
              <h2 className="heading-h2 semi text-darker">{description}</h2>
              <Whitepaper
                pageContext={{ translate }}
                btnText={translate.t?.book_a_demo}
                styler="greenbtn"
                title={translate.t?.contact_form}
                description={translate.t?.contact_form_description}
                type="contact"
                submitBtnText={translate.t?.submit}
              />
            </div>
            <img src={img} alt="Positive" />
          </div>
        </div>
      }
    </>
  )
}

export default Positive
