import * as React from "react"
import positive1 from "../../assets/images/positive1.jpg"
import Positive from "./positive"
import Hero from "../roaster/hero"
import Pills from "./pills"
import Join from "../roaster/join"

import Fazenda from "../../assets/images/Fazenda_California.webp"
import Luiz from "../../assets/images/Ama_Luiz.webp"
import Fredrik from "../../assets/images/Fredrik_Lo_fbergs.webp"

const CommunityPage = ({ pageContext: { translate } }) => {
  const dataRoaster = [
    {
      img: Fazenda,
      name: "Flavia and Luiz Saldanha Rodrigues ",
      designation: "Fazenda California",
      quote: translate.t?.public_quote_1,
    },
    {
      img: Luiz,
      name: "Luiz Paulo Dias Pereira Filho",
      designation: "Ama Coffee Producer",
      quote: translate.t?.public_quote_2,
    },
    {
      img: Fredrik,
      name: "Fredrik Nilsson",
      designation: "Löfbergs",
      quote: translate.t?.public_quote_3,
    },
  ]
  const dataEstates = [
    {
      img: Fredrik,
      name: "Fredrik Nilsson",
      designation: "Löfbergs",
      quote: translate.t?.public_quote_3,
    },
  ]

  const dataCustomers = []

  const dataFacilitators = []
  return (
    <>
      {
        <div className="community_main_page">
          <div className="roaster_page">
            <Hero
            pageContext={{ translate }}
              title={translate.t?.our_users_share}
              description={translate.t?.our_community_consists}
              btntext={translate.t?.find_your_solution}
              btnLink={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }solutions/`}
            />
          </div>
          <Positive
          pageContext={{ translate }}
            img={positive1}
            description={translate.t?.join_community_positive_change}
            btntext={translate.t?.book_a_demo}
            btnlink=""
          />
          <Pills
            dataRoaster={dataRoaster}
            dataEstates={dataEstates.splice(0, 2)}
            dataCustomers={dataCustomers.splice(0, 1)}
            dataFacilitators={dataFacilitators.splice(0, 1)}
          />
          <div className="joiner-second">
            <Join pageContext={{ translate }} />
          </div>
        </div>
      }
    </>
  )
}

export default CommunityPage
